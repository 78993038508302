<template>
  <div class="view pa24">
    <div class="d-flex mb20">
      <div class="bgf7fa pt20 pr20 pl20 pb20 d-flex flex-column mr20 w600">
        <p>成员名称：{{ memberInfo.name }}</p>
        <p>合作伙伴名称：{{ memberInfo.employeeName }}</p>
        <p>转介绍客户总数：{{ memberInfo.customerNum }}</p>
        <p>已成交客户数：{{ memberInfo.customerNum }}</p>
        <p>成交总金额：￥{{ memberInfo.shopSell + memberInfo.manualSell }}</p>
        <p>推广赏金支出金额：￥{{ memberInfo.partnerRate + memberInfo.partnerRateWait }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center">

      介绍客户名称：<el-input class="mr20 w200" v-model="keyword" placeholder="请输入客户名称"></el-input>
      手机号：<el-input class="mr20 w200" v-model="phone" placeholder="请输入手机号"></el-input>
      <el-select v-model="categoriesValue" class="w120 mr10">
        <el-option v-for="(item, index) in options" :key="index" :label="item.title" :value="item.type">
        </el-option>
      </el-select>
      <el-date-picker v-model="pickerValue" class="mr10 " align="right" value-format="yyyy-MM-dd" type="daterange"
        start-placeholder="开始日期" end-placeholder="结束日期" />
      <el-button type="primary" @click="queryVisitorsData">查询</el-button>
      <!-- <el-button type="primary" @click="queryVisitorsData">导出</el-button> -->
    </div>

    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column prop="customerName" align="center" label="介绍客户名称" />
        <el-table-column prop="customerPhone" align="center" label="手机号" />
        <el-table-column prop="customerWechat" align="center" label="微信号" />
        <el-table-column prop="createdTime" align="center" label="介绍时间" />
        <el-table-column prop="type" align="center" label="来源">
          <template slot-scope="scope">
            <p>{{ scope.row.type == 1 ? '直接线索' : '分享名片' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="状态">
          <template slot-scope="scope">
            <!-- <p>{{ scope.row.state == 1 ? '未跟进' : scope.row.state == 2 ? '已跟进' : '已成交' }}</p> -->
            <el-tag v-if="scope.row.status == 1" type="info">未跟进</el-tag>
            <el-tag v-else-if="scope.row.status == 2">已跟进</el-tag>
            <el-tag v-else>已成交</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="manualSell" align="center" label="录入成交金额" />
        <el-table-column prop="shopSell" align="center" label="商城交易金额" />
        <el-table-column prop="partnerRate" align="center" label="推广赏金支出金额" />
        <el-table-column prop="visitTime" align="center" label="操作">
          <template slot-scope="scope">
            <p class="cblue hover_pointer" @click="toDetailPage(scope.row)">详情</p>
          </template>
        </el-table-column>

      </template>
    </commonTable>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import {
  bossSubordinationBalance
} from "@/api/financing";

import {
  customerList,
  employeeDetails,
  details
} from "@/api/turnToIntroduce";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      phone: "",
      startTime: this.startTime,
      endTime: this.endTime,
      keyword: '',
      options: [
        {
          title: '全部',
          type: ''
        },
        {
          title: '未被跟进',
          type: 1
        },
        {
          title: '跟进中',
          type: 2
        },
        {
          title: '已成交',
          type: 3
        },
      ],
      categoriesValue: '',
      memberInfo: ''
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  watch: {
    categoriesValue(val, old) {
      //-console.log(val)
    }
  },
  created() {
    this.queryVisitorsData();
    this.details()
  },
  methods: {
    async queryVisitorsData() {
      var patrn = /^(-)?\d+(\.\d+)?$/;
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        customerPhone: this.phone,
        empId: this.$route.query.empId,
        userId: this.$route.query.userId,
        customerName: this.keyword,
        state: this.categoriesValue,
        startTime: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        endTime: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
      };

      if (this.keyword) {
        if (patrn.exec(this.keyword)) {
          data.phone = this.keyword
        } else {
          data.companyName = this.keyword
        }
      }
      try {
        this.loading = true;
        const result = await customerList(data);
        this.loading = false;
        const { total, list } = result?.data;
        this.tableData = list;
        this.tableData.forEach(item => {
          item.balance = (item.balance / 100).toFixed(2)
          item.commission = (item.commission / 100).toFixed(2)
          item.totalPay = (item.totalPay / 100).toFixed(2)
        })
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    details() {
      let data = {
        epId: this.$route.query.epId
      }
      details(data)
        .then(res => {
          //-console.log(res)
          if (res && res.data) {
            this.memberInfo = res.data
          }
        })
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60);
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      var result = "" + parseInt(theTime) + "秒";
      if (theTime1 > 0) {
        result = "" + parseInt(theTime1) + "分" + result;
      }
      if (theTime2 > 0) {
        result = "" + parseInt(theTime2) + "小时" + result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
    toDetailPage(item) {
      if (!item.customerCardId) {
        this.$message.error('此客户尚未被成员存为客户，无法查看')
        return
      }
      this.$router.push('../customerDetall?id=' + item.customerCardId)
    }
  },
};
</script>

<style lang="scss" scoped></style>